import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import store from "../../redux/store";
import {
  get_files,
  get_theme_change,
  set_open,
  set_upload_images,
} from "../../redux/slice/dataSlice";
import { useSelector } from "react-redux";
import SmallModal from "../../components/Modal/SmallModal";
import { FiUpload } from "react-icons/fi";

import DCMFile from "../../assets/case1_008.dcm";
import DiconViewer from "../../components/DicomViewer/DiconViewer";
import Modal from "../../components/Modal/Modal";
import ProfileLayout from "../../components/Layout/ProfileLayout";

const Dashboard = () => {
  // const [annotationMode, setAnnotationMode] = useState("polygon");
  const [annotations, setAnnotations] = useState([]);

  const {
    theme,
    files,
    open,
    openmodal,
    do_animation,
    files_data,
    show_image,
  } = useSelector((state) => state.data);
  const fileInputRef = useRef(null);

  // useEffect(()=>{
  //   store.dispatch(set_open(true))
  // },[])

  console.log("open ===", open);

  console.log("files_data ===", files_data);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // const handleFileChange = async (event) => {
  //   const selectedFiles = event.target.files;
  //   // store.dispatch(get_files(selectedFiles));
  //   console.log(selectedFiles);
  //  await store.dispatch(set_upload_images(selectedFiles))
  // };

  // const handleFileChange = async (event) => {
  //   const selectedFiles = event.target.files;

  //   console.log("selectedFiles ===", selectedFiles);

  //   if (!selectedFiles || selectedFiles.length === 0) return;
  //   const filesArray = Array.from(selectedFiles);
  //   await store.dispatch(set_upload_images(filesArray));
  // };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0]; 
  
    console.log("selectedFile ===", selectedFile);
  
    if (!selectedFile) return;
    await store.dispatch(set_upload_images([selectedFile])); 
  };
  

  return (
    <div className="flex h-full w-full object-cover gap-3 flex-col">
      <DiconViewer
        annotations={annotations}
        setAnnotations={setAnnotations}
        url={
          process.env.REACT_APP_BASE_URL +
          "images/" +
          files_data[show_image]?.original_url
        }
        overlay_url={
          process.env.REACT_APP_BASE_URL +
          "images/" +
          files_data[show_image]?.detected_url
        }
      />
      {open && (
        <SmallModal isOpen={open} title={"alert"}>
          <div className="text-light-text dark:text-dark-text flex flex-col gap-5 mt-5 mb-5 ml-5 mr-5">
            <div className="text-[24px] ">
              Your Journey to Insight Begins Here
            </div>
            <div className="font-normal">
              Easily upload your CT scans to explore and analyze with advanced
              tools.
            </div>
            <div className="w-full flex justify-center items-center">
              <Button
                title={"Upload CT Scan"}
                lefticon={<FiUpload size={20} />}
                class_name={
                  "bg-light-active dark:bg-dark-active text-light-text2 dark:text-dark-text2 font-normal"
                }
                w="218px"
                onClick={handleButtonClick}
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                // multiple
                accept=".dcm,.jpeg,.png"
                style={{ display: "none" }}
              />
            </div>
          </div>
        </SmallModal>
      )}

      {openmodal && (
        <Modal
          animation={do_animation}
          isOpen={openmodal}
          title={"CHEQUE CLEARANCE"}
          body={
            <ProfileLayout>
              <Outlet />
            </ProfileLayout>
          }
        />
      )}
    </div>
  );
};

export default Dashboard;
